import React, { Component } from "react";

import Item from "./item";
import Container from "../container";

import maeRight from "../../images/mae-right.svg";
import homeRight from "../../images/home-right.svg";

export class Wrapper extends Component {
  render() {
    const list = [
      {
        caseNo: "01",
        name: "Mae's Cookbook",
        header: "Mae’s Cookbook",
        body: "The creation of Mae’s cookbook is fueled by the desire to provide a database of recipes where users can have a directory of their favorite meals. To make the UX more extended, it allows users to search for its recipes by the supplies they have, preferred cuisine types, occasion and more",
        url: "work/maecookbook",
        newClass: "mae",
        right: `${homeRight}`
      },
      {
        caseNo: "02",
        name: "HomeDoc",
        header: "Designing HomeDoc",
        body: "HomeDoc is an healthcare mobile application that provides parents and custodians the opportunity to have rapid medical consultations, book medical visits, among other features. A platform that provides accessible, data-efficient and affordable medical services for children.",
        url: "#",
        newClass: "homedoc",
        right: `${maeRight}`
      },
    ];
    return (
      <section className="projects">
        <Container>
          <h2 className="projects__header">Case Studies</h2>
          <div className="casestudy">
            {list.map(({ name, body, caseNo, header, newClass, url, right }) => (
              <Item
                name={name}
                title={header}
                caseNo={caseNo}
                url={url}
                newClass={newClass}
                key={caseNo}
                body={body}
                right={right}
              />
            ))}
          </div>
        </Container>
      </section>
    );
  }
}

export default Wrapper;
