import React, { Component } from "react";
import { motion } from "framer-motion";
import Container from "./container";
// import Slider from "react-slick";
import Marquee from "react-fast-marquee";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import email from "../images/message.gif";
import polyone from "../images/polygon-one.svg";
import polytwo from "../images/polygon-two.svg";

export class Cta extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      speed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      fade: true,
    };

    const variants = {
      hidden: {
        y: 40,
        opacity: 0,
      },
      visible: {
        y: 0,
        opacity: 1,
        transition: { delay: 0.8, duration: 1 },
      },
    };

    return (
      <motion.section
        variants={variants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="cta"
      >
        <img className="cta__floatone" src={polyone} />
        <img className="cta__floattwo" src={polytwo} />
        <Container>
          <div className="cta__content">
            <p>We can-</p>
            <div className="cta__marquee">
              <Marquee gradient={false} speed={200}>
                <h2>create the next pixel-perfect solutions.</h2>
                <h2>Visualize your ideas.</h2>
                <h2>
                  Imagine products that don't exist and guide them to life.
                </h2>
                <h2>
                  Craft the best experiences through the imaginative use of
                  technology.
                </h2>
                <h2>
                  Breakdown challenging problems to create delightful
                  experiences.
                </h2>
                <h2>
                  Use design to bridge the gap between humans and technology.
                </h2>
              </Marquee>
            </div>
            {/* <Slider {...settings}> 
                       <h2>create the next pixel-perfect solutions.</h2>
                       <h2>Visualize your ideas.</h2>
                       <h2>Imagine products that don’t exist and guide them to life.</h2>
                       <h2>Craft the best experiences through the imaginative use of technology.</h2>
                       <h2>Breakdown challenging problems to create delightful experiences.</h2>
                       <h2>Use design to bridge the gap between humans and technology.</h2>
                    </Slider>  */}
            {/* <hr /> */}
            {/* <div className="cta__email">
                        <img src={email} alt="Email Icon" />
                        <a href="mailto:oreoluwanimiadey@gmail.com">oreoluwanimiadey@gmail.com</a> 
                    </div> */}
          </div>
        </Container>
      </motion.section>
    );
  }
}

export default Cta;
