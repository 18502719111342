import { Link } from "gatsby";
import React, { Component } from "react";
import { motion } from "framer-motion";

import arrow from "../../images/arrow.svg";
import noise from "../../images/noise.png";

export class Item extends Component {
  render() {
    const { caseNo, title, url, body, newClass, right } = this.props;

    const listVariants = {
      hidden: {
        opacity: 0,
        y: 200,
      },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 1,
        },
      },
    };

    return (
      <div className="projectitem__wrap">
        <motion.div
          variants={listVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className={`projectitem ${newClass}`}
        >
          <div className="projectitem__content">
            <div className="projectitem__info">
              <h3>{caseNo}</h3>
              <h4>{title}</h4>
              <p>{body}</p>
            </div>
            <Link to={url}>
              <button className="btn">
                <p>Read Case Study</p>
                <img src={arrow} alt="Arrow" />
              </button>
            </Link>
          </div>
          <div className="right">
            <img src={right} alt="Right design" />
          </div>
          <div className="noise">
            <img src={noise} alt="Right design" />
          </div>
        </motion.div>
      </div>
    );
  }
}

export default Item;
