import React, { Component } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from "../container";

import one from "../../images/exploration/one.jpg";
import two from "../../images/exploration/two.jpg";
import three from "../../images/exploration/three.jpg";
import four from "../../images/exploration/four.jpg";
import five from "../../images/exploration/five.jpg";
import six from "../../images/exploration/six.jpg";
import seven from "../../images/exploration/seven.jpg";
import eight from "../../images/exploration/eight.jpg";
import ExplorationItem from "./item";

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      speed: 3000,
      slidesToShow: 2.05,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,

      /*   cssEase: "linear", */
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true
          },
        },
      ],
    };

    const exploreVariants = {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
        transition: { delay: 0.5, duration: 0.5 },
      },
    };

    const items = [
      {
        position: 1,
        image: `${one}`,
        intro: "LANDING PAGE FOR FINANCER",
      },
      {
        position: 2,
        image: `${two}`,
        intro: "NAVIGATION BAR EXPLORATION FOR ELLIAS",
      },
      {
        position: 3,
        image: `${three}`,
        intro: "LANDING PAGE FOR PAYONSITE",
      },
      {
        position: 4,
        image: `${four}`,
        intro: "ONBOARDING EXPLORATION FOR TRACKER",
      },
      {
        position: 5,
        image: `${five}`,
        intro: "SIGN UP PAGE FOR CARDIFY",
      },
      {
        position: 6,
        image: `${six}`,
        intro: "CHECKOUT EXPLORATION FOR TROREADER",
      },
      {
        position: 7,
        image: `${seven}`,
        intro: "LANDING PAGE FOR FINANCER",
      },
      {
        position: 8,
        image: `${eight}`,
        intro: "CONTACT EXPLORATION FOR NOVATUITION",
      },
    ];

    return (
      <motion.div
        className="exploration"
        variants={exploreVariants}
        initial="hidden"
        animate="visible"
        viewport={{ once: true }}
      >
        <Container>
          <div className="exploration__header">
            <h2> Explorations</h2>
            {/* <div style={{ textAlign: "center" }}>
              <button className="slider-btn previous" onClick={this.previous}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="slider-btn__circle"
                    cx="16"
                    cy="16"
                    r="16"
                    fill="#666666"
                  />
                  <path
                    d="M18.347 21.9307L12.459 16.0853L18.347 10.24L19.2003 11.0933L14.1657 16.0853L19.2003 21.0773L18.347 21.9307Z"
                    fill="black"
                  />
                </svg>
              </button>

              <button className="slider-btn next" onClick={this.next}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="slider-btn__circle"
                    cx="16"
                    cy="16"
                    r="16"
                    fill="#666666"
                  />
                  <path
                    d="M13.653 21.9307L19.541 16.0853L13.653 10.24L12.7997 11.0933L17.8343 16.0853L12.7997 21.0773L13.653 21.9307Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div> */}
          </div>

          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {items.map((item) => {
              if (item.position % 2 === 1) {
                return (
                  <ExplorationItem
                    image={item.image}
                    intro={item.intro}
                    extra="odd"
                  />
                );
              } else {
                return (
                  <ExplorationItem
                    image={item.image}
                    intro={item.intro}
                    extra="even"
                  />
                );
              }
            })}
          </Slider>
        </Container>
      </motion.div>
    );
  }
}
