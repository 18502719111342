import React, { Component } from 'react';

export class ExplorationItem extends Component {
  render() {

    const { image, intro, extra} = this.props
    return (
        <div className={"exploration__item " + extra}>
            <img src={image} alt={intro} />
            <p>{intro}</p>
        </div>
    );
    
  }
}

export default ExplorationItem;
