import * as React from "react";
import Cta from "../components/cta";
import Experience from "../components/experience/wrapper";
import Carousel from "../components/exploration";
import Footer from "../components/footer";
import HeroHeader from "../components/heroheader";
import Wrapper from "../components/projects/wrapper";
import Resources from "../components/resources/wrapper";
import "../styles/index.scss";

const IndexPage = () => {
  return (
    <main className="dark">
      <title>Home Page</title>
      <HeroHeader />
      <Wrapper />
      <Carousel />
      <Experience />
      <Resources />
      <Cta />
      <Footer />
    </main>
  );
};

export default IndexPage;
