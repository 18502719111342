import React, { Component } from "react";
import Container from "../container";
import Header from "./header";
import Item from "./item";

import freelancedesign from "../../images/resources/designcontract.png";

export class Resources extends Component {
  render() {
    return (
      <section className="resources">
        <Header />
        <Container>
          <div className="resources__content">
            <div className="resources__items">
              <Item
                title="Design Contract Template"
                type="Google Doc"
                description="This suggests how to set expectations and condition for services provided."
                url="#"
                image="https://res.cloudinary.com/kadet/image/upload/v1646562845/ore-portfolio/Rectangle_901_s6v30p.png"
              />
              <Item
                title="For every Junior Dsgnr"
                type="Medium"
                description="A plethora of resources that have helped me in my Product Design journey."
                url="#"
                image="https://res.cloudinary.com/kadet/image/upload/v1646562845/ore-portfolio/IMG_6798_owyt4f.png"
              />
              <Item
                title="Product Brief"
                type="Notion"
                description="This  puts your facts and other relevant info regarding design projects in writing."
                url="#"
                image="https://res.cloudinary.com/kadet/image/upload/v1646562856/ore-portfolio/IMG_6799_tadqux.png"
              />
              <Item
                title="UX Writing"
                type="Medium"
                description="Stringing words that perfectly communicates your desired."
                url="#"
                image="https://res.cloudinary.com/kadet/image/upload/v1646562853/ore-portfolio/IMG_6797_qtwscz.jpg"
              />
            </div>
            <div className="resources__image" data-animation="resources-image">
              <img src={freelancedesign} alt="Resources" />
            </div>
          </div>
        </Container>
      </section>
    );
  }
}

export default Resources;
