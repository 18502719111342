import { motion } from "framer-motion";
import React, { Component } from "react";

import scrolldown from "../images/scroll-down.gif";
import Container from "./container";
import Nav from "./nav";

export class HeroHeader extends Component {
  render() {
    const nameText = "OREOLUWANIMI ADEYEMI".split("");
    const firstIntro = "Digital Product Designer.".split("");
    const secondIntro = "Creative.".split("");
    const descText =
      "I design products that put the user's needs into account at every stage of the product's lifestyle.".split(
        ""
      );

    return (
      <motion.div className="hero" data-animation="hero">
        <Nav />
        <Container>
          <div className="hero__content">
            <div className="hero__textwrap">
              <h5 className="hero__fullname" data-animation="hero-name">
                {nameText.map((item, index) => (
                  <span key={index}>
                    {item}
                  </span>
                ))}
              </h5>

              <div className="hero__description">
                <h1>
                  <div className="firstIntro">
                    {firstIntro.map((item, index) => (
                      <span key={index} data-animation="first-intro">
                        {item}
                      </span>
                    ))}
                  </div>
                  <br />
                  <div className="secondIntro">
                    {secondIntro.map((item, index) => (
                      <span key={index} data-animation="second-intro">
                        {item}
                      </span>
                    ))}
                  </div>
                </h1>
              </div>

              <p data-animation="description">
                {descText.map((item, index) => (
                  <span key={index}>
                    {item}
                  </span>
                ))}
              </p>
            </div>

            <img
              src={scrolldown}
              alt="Scrolldown arrow"
              className="scroll-down"
              data-animation="arrow"
            />
          </div>
        </Container>
      </motion.div>
    );
  }
}

export default HeroHeader;
