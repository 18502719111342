import React from "react";

export default function Item({ url, title, type, description, image }) {
  return (
    <a className="resources__item" href={url} data-animation="resources-item" data-src={image}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.7405 0.183925C16.7403 0.183925 16.7402 0.184011 16.7401 0.183925L1.18375 0.183948C0.631577 0.19353 0.191706 0.648938 0.201286 1.20111C0.210694 1.73981 0.645126 2.17424 1.18374 2.18356L14.3261 2.18363L0.476614 16.0331C0.0861164 16.4236 0.0861155 17.0568 0.476612 17.4473C0.867109 17.8378 1.50033 17.8378 1.89083 17.4473L15.7403 3.59784L15.7402 16.7402C15.7306 17.2924 16.1705 17.7478 16.7226 17.7573C17.2749 17.767 17.7304 17.327 17.74 16.7749C17.7402 16.7633 17.7402 16.7517 17.7399 16.7401L17.7399 1.18373C17.7401 0.631648 17.2925 0.184096 16.7405 0.183925Z"
          fill="white"
        />
      </svg>
      <div className="beneath">
        <h3>{title}</h3>
        <div className="right">
          <h6>{type}</h6>
          <p>{description}</p>
        </div>
      </div>
    </a>
  );
}
